import {Button, Form, Input} from "antd"
import React from "react"
import {useNavigate} from "react-router-dom"
import './change-password.page.css'
import {defaultSettings, PasswordInput} from "antd-password-input-strength";
import Title from "antd/es/typography/Title";
import {notificationActions} from "../../store/notification.state";
import {loginActions} from "../../store/login.state";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {userChangePassword} from "../../service/user.service";

interface ChangePassword {
    readonly currentPassword: string
    readonly newPassword: string
    readonly confirmPassword: string
}

const ChangePasswordPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector((state: RootState) => state.login.user)

    const login = ({currentPassword, newPassword}: ChangePassword) => {

        userChangePassword(user!.id, currentPassword, newPassword).subscribe(() => {
            dispatch(notificationActions.success("Salvataggio avvenuto con successo"))
            dispatch(loginActions.logout())

            navigate('/login')
        })
    }

    return (
        <>
            <Title level={4} style={{marginBottom: '10px'}}>Per applicare le modifiche sarà necessario rifare
                l'accesso.</Title>
            <Form
                layout="vertical"
                size="middle"
                initialValues={{remember: false}}
                onFinish={login}
                autoComplete="off"
            >
                <Form.Item
                    label="Password corrente"
                    name="currentPassword"
                    rules={[{required: true, message: 'Password corrente obbligatoria'}]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    label="Nuova password"
                    name="newPassword"
                    rules={[{required: true, message: 'Nuova password obbligatoria'}]}
                >
                    <PasswordInput settings={{
                        ...defaultSettings,
                        alwaysVisible: true,
                        height: 5
                    }}/>
                </Form.Item>

                <Form.Item
                    label="Conferma password"
                    name="confirmPassword"
                    rules={[({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Le due password non sono uguali.'));
                        }
                    })
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <div style={{marginTop: "10px", display: "flex", justifyContent: "end"}}>
                    <Button type="primary" htmlType="submit">
                        Salva
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default ChangePasswordPage