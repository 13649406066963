import {Button, Col, Image, Row} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {IEvent} from "../../model/event.model";
import {formatterDate} from "../../util/date.formatter";
import {getEvent} from "../../service/event.service";
import {pageLoadingSActions} from "../../store/page-loading.state";
import {useDispatch} from "react-redux";

const EventDetailPage = () => {
    const [event, setEvent] = useState<IEvent>({} as IEvent)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    useEffect(() => {
        dispatch(pageLoadingSActions.showLoading())
        getEvent(params.eventId!).subscribe(value => {
            setEvent(value)
            dispatch(pageLoadingSActions.hideLoading())
        })
    }, [params.eventId])

    const navigateTo = () => {
        navigate('./subscription/first-step')
    }

    const backToHome = () => {
        navigate('/')
    }

    return (
        <Row style={{textAlign: "center"}}>
            {event.id && <>
                <Col span={24}>
                    <h1 style={{
                        textAlign: "center",
                        fontSize: "30px",
                        marginBottom: "30px"
                    }}>{formatterDate(event.date)} - {event.description}</h1>
                </Col>
                {!event.active && <Col span={24}>
                    <h2>Iscrizioni chiuse</h2>
                </Col>
                }
                <Col span={24}>
                    <div className="detail buttons-container" style={{justifyContent: "center"}}>
                        <Button type="default" onClick={backToHome}>
                            Torna alla home
                        </Button>
                        {event.active ? <Button type="primary" onClick={navigateTo}>
                            Vai alle iscrizioni online
                        </Button> : <></>
                        }
                    </div>
                    <Image
                        style={{maxWidth: "800px"}}
                        src={event.bigUrl}
                    />
                </Col>
            </>
            }
        </Row>
    )
}

export default EventDetailPage