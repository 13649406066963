import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Modal, Row, Select, Space, Table} from 'antd'
import {ISubscriptionData} from "../../../model/subscription.model"
import Column from "antd/es/table/Column"
import {Observable} from "rxjs"
import {pipe} from "fp-ts/function"
import * as OBS from 'fp-ts-rxjs/Observable'
import {IEvent} from "../../../model/event.model"
import {DisciplineType, disciplineTypeData} from "../../../model/discipline.model"
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";

import './subscriptions.page.css'
import {formatterDate} from "../../../util/date.formatter";
import {exportSchoolSubscriptions, exportSubscriptions} from "../../user/export-handler.service";
import * as RA from "fp-ts/ReadonlyArray";
import * as O from "fp-ts/Option";
import {ISchoolEventCost} from "../../../model/school.model";
import {fetchEvents} from "../../../service/event.service";
import {getSchoolTotalCostByEvent} from "../../../service/school.service";
import {deleteSubscription, fetchSubscriptions} from "../../../service/subscription.service";


interface SubscriptionFilters {
    readonly eventId?: number,
    readonly disciplineType: DisciplineType
}

const SubscriptionsPage = () => {
    const [subscriptions, setSubscriptions] = useState([] as ReadonlyArray<ISubscriptionData>)
    const [currentSubscription, setCurrentSubscription] = useState<ISubscriptionData | undefined>(undefined)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [events, setEvents] = useState([] as ReadonlyArray<IEvent>)
    const [filters, setFilters] = useState({} as SubscriptionFilters)
    const [loading, setLoading] = useState(false)
    const [cost, setCost] = useState({} as ISchoolEventCost)

    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const school = useSelector((state: RootState) => state.school)
    const login = useSelector((state: RootState) => state.login)

    useEffect(() => {
        setLoading(true)

        fetchEvents(1).subscribe(value => {
            setEvents(value)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (filters.eventId) {
            retrieveCost()
        }
        if (filters.eventId && filters.disciplineType) {
            refreshData()
        }
    }, [filters.eventId, filters.disciplineType])

    const retrieveCost = () => {
        getSchoolTotalCostByEvent(filters.eventId!, school.id!)
            .subscribe(data => setCost(data))
    }

    const refreshData = () => {
        setSubscriptions([])
        setLoading(true)

        buildSubscriptionOBS().subscribe(data => {
            setSubscriptions(data)
            setLoading(false)
        })
    }

    const buildSubscriptionOBS = (): Observable<ReadonlyArray<ISubscriptionData>> => {
        const disciplineType = filters.disciplineType === 3 ? [3, 4, 5] : [filters.disciplineType]
        return fetchSubscriptions(school.id!, filters.eventId!, disciplineType)
    }

    const handleEventFilter = (eventId: number) => {
        setFilters({
                ...filters,
                eventId
            }
        )
    }

    const handleDisciplineTypeFilter = (disciplineType: DisciplineType) => {
        setFilters({
            ...filters,
            disciplineType
        })
    }

    const showModal = (subscription: ISubscriptionData) => {
        setCurrentSubscription(subscription)
        setIsModalOpen(true)
    }

    const handleDelete = () => {
        setLoading(true)
        setIsModalOpen(false)
        pipe(
            deleteSubscription(currentSubscription!.id),
            OBS.chain(() => buildSubscriptionOBS())
        ).subscribe(data => {
            setSubscriptions(data)
            setLoading(false)
        })
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const getDataToDelete = (): string => {
        switch (filters.disciplineType) {
            case 1:
                return currentSubscription?.competitor1 + ""
            case 2:
                return currentSubscription?.competitor1 + " e " + currentSubscription?.competitor2
            case 3:
            case 4:
            case 5:
                return currentSubscription?.groupName + ""
        }
    }

    const retrieveEventNameFromFilter = (): string => {
        return pipe(
            events,
            RA.findFirst(event => event.id === filters.eventId!),
            O.map(event => event.description),
            O.getOrElse(() => " - ")
        )
    }

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                size="middle"
                name="filtersForm"
            >
                <Row gutter={100} justify="space-between" style={{marginTop: "30px", marginBottom: "15px"}}>
                    <Col span={12} style={{alignContent: "center"}}>
                        {
                            filters.eventId && (
                                <div>
                                    {
                                        cost && cost.iban && cost.cost > 0 && (
                                            <h3 style={{margin: "0"}}>Per effettuare il pagamento di {cost.cost} €, fare un
                                                bonifico su {cost.iban}</h3>
                                        )
                                    }
                                </div>
                            )
                        }
                    </Col>
                    <Col span={12}>
                        {
                            filters.eventId && (
                                <div className="subscriptions buttons-container">
                                    {
                                        (login.user?.eventOwner === filters.eventId || login.user?.authority === "ROLE_ADMIN") &&
                                        <Button
                                            type="primary"
                                            onClick={() => exportSubscriptions(dispatch, filters.eventId!, retrieveEventNameFromFilter())}>
                                            Scarica il riepilogo dell'evento
                                        </Button>
                                    }
                                    <Button type="primary"
                                            onClick={() => exportSchoolSubscriptions(dispatch, filters.eventId!, school, retrieveEventNameFromFilter())}>
                                        Scarica il riepilogo delle tue iscrizioni
                                    </Button>
                                </div>
                            )
                        }
                    </Col>
                </Row>
                <Row gutter={100} justify="space-between">
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Evento"
                            key={"eventId"}
                            name="eventId"
                        >
                            <Select
                                placeholder="Seleziona un evento"
                                options={events.map((item) => ({
                                    label: formatterDate(item.date) + " - " + item.description,
                                    value: item.id
                                }))}
                                onChange={(value) => handleEventFilter(value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Tipologia"
                            key={"type"}
                            name="type"
                        >
                            <Select
                                placeholder="Seleziona un tipo di disciplina"
                                options={disciplineTypeData.map((item) => ({
                                    label: item.label,
                                    value: item.type
                                }))}
                                onChange={(value) => handleDisciplineTypeFilter(value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                id="subscriptionsTable"
                scroll={{x: "max-content"}}
                dataSource={subscriptions}
                loading={loading}
                rowKey={(record) => record.id + ' ' + Math.random()}
                pagination={{position: ['bottomCenter']}}>
                {filters.disciplineType !== 3 && filters.disciplineType !== 4 && filters.disciplineType !== 5 && (
                    <>
                        {filters.disciplineType === 1 && (<>
                            <Column title="Atleta" dataIndex="competitor1" key="competitor1"/>
                        </>)}
                        {filters.disciplineType === 2 && (<>
                            <Column title="Primo Atleta" dataIndex="competitor1" key="competitor1"/>
                            <Column title="Secondo Atleta" dataIndex="competitor2" key="competitor2"/>
                        </>)}
                    </>
                )}
                {(filters.disciplineType === 3 || filters.disciplineType === 4 || filters.disciplineType === 5) && (
                    <Column title="Gruppo - Nome coreografia - Numero atleti" dataIndex="group" key="group"/>
                )}
                <Column title="Disciplina" dataIndex="discipline" key="discipline"/>
                <Column title="Categoria" dataIndex="category" key="category"/>
                <Column title="Classe" dataIndex="class" key="class" align={"center"}/>
                {(filters.disciplineType === 1 || filters.disciplineType === 2) && (
                    <>
                        <Column title="Nome coreografia" dataIndex="choreographyName" key="choreographyName"/>
                    </>
                )}
                <Column title="Costo" dataIndex="cost" key="cost" align={"right"} render={value => value + ' €'}/>
                <Column
                    key="action"
                    render={(value) => (
                        <Space size="middle">
                            <a onClick={() => showModal(value)}>Cancella</a>
                        </Space>
                    )}
                />
            </Table>
            {
                currentSubscription &&
                (<Modal title="Cancella iscrizione" open={isModalOpen} onOk={handleDelete} onCancel={handleCancel}>
                    <div>
                        <span>Sei sicuro di voler cancellare l'iscrizione di </span>
                        <strong>{getDataToDelete()}</strong>
                    </div>
                    <div>
                        <span> dalla </span>
                        <strong>{currentSubscription.discipline} {currentSubscription.category} {currentSubscription.class}</strong>
                    </div>
                    <div>
                        <span> della gara </span>
                        <strong>{retrieveEventNameFromFilter()}</strong>
                        <span>?</span>
                    </div>
                </Modal>)
            }
        </>
    )
}

export default SubscriptionsPage