import API from "../api";
import {pipe} from "fp-ts/function";
import {from, Observable} from "rxjs";
import * as OBS from "fp-ts-rxjs/Observable";
import {ISchool, ISchoolEventCost} from "../model/school.model";

const BASE_PATH = 'school'

export const getSchoolTotalCostByEvent = (eventId: number | string, schoolId: number): Observable<ISchoolEventCost> => {
    return pipe(
        from(
            API.get(BASE_PATH + "/costByEvent", {
                params: {
                    eventId,
                    schoolId
                }
            })
        ),
        OBS.map((res): ISchoolEventCost => res.data)
    )
}

export const createSchool = (data: ISchool): Observable<any> => {
    return pipe(
        from(
            API.post(BASE_PATH, {
                name: data.schoolName,
                fiscalCode: data.schoolFiscalCode,
                email: data.schoolEmail,
                phone: data.schoolPhone,
                zone: data.schoolZone,
                personName: data.contactPersonName,
                personFiscalCode: data.contactPersonFiscalCode,
                personEmail: data.contactPersonEmail,
                personPhone: data.contactPersonPhone
            })
        ),
        OBS.map(res => res.data)
    )
}

export const updateSchool = (schoolId: number, data: ISchool): Observable<any> => {
    return pipe(
        from(
            API.put(BASE_PATH, {
                id: schoolId,
                name: data.schoolName,
                fiscalCode: data.schoolFiscalCode,
                email: data.schoolEmail,
                phone: data.schoolPhone,
                zone: data.schoolZone,
                personName: data.contactPersonName,
                personFiscalCode: data.contactPersonFiscalCode,
                personEmail: data.contactPersonEmail,
                personPhone: data.contactPersonPhone
            })
        ),
        OBS.map(res => res.data)
    )
}

export const findSchoolById = (id: number): Observable<ISchool> => {
    return pipe(
        from(
            API.get(BASE_PATH, {
                params: {
                    id
                }
            })
        ),
        OBS.map((res): ISchool => res.data)
    )
}
