import API from "../api";
import {pipe} from "fp-ts/function";
import {from, Observable} from "rxjs";
import * as OBS from "fp-ts-rxjs/Observable";
import {ICategory} from "../model/category.model";

const BASE_PATH = 'category'

export const fetchCategories = (eventId: string, disciplineId: number): Observable<ReadonlyArray<ICategory>> => {
    return pipe(
        from(
            API.get(BASE_PATH, {
                params: {
                    eventId,
                    disciplineId
                }
            })
        ),
        OBS.map((res): ReadonlyArray<ICategory> => res.data)
    )
}
