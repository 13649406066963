import API from "../api";
import {pipe} from "fp-ts/function";
import {from, Observable} from "rxjs";
import * as OBS from "fp-ts-rxjs/Observable";
import {IEvent} from "../model/event.model";

const BASE_PATH = 'event'

export const fetchEvents = (active: number): Observable<IEvent[]> => {
    return pipe(
        from(
            API.get(BASE_PATH + "/all", {
                params: {
                    active
                }
            })
        ),
        OBS.map((res): IEvent[] => res.data)
    )
}

export const getEvent = (id: string): Observable<IEvent> => {
    return pipe(
        from(
            API.get(BASE_PATH, {
                params: {
                    id
                }
            })
        ),
        OBS.map((res): IEvent => res.data)
    )
}
