import {Col, Form, Input, Row, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ICompetitor} from "../../../model/competitor.model";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {fetchCompetitors} from "../../../service/competitor.service";
import Column from "antd/es/table/Column";
import {debounceTime, distinctUntilChanged, Subject} from "rxjs";

interface CompetitorsPageFilters {
    readonly search?: string
}

const CompetitorsPage = () => {
    const [competitors, setCompetitors] = useState([] as ReadonlyArray<ICompetitor>)
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({} as CompetitorsPageFilters)

    const [form] = Form.useForm()

    const school = useSelector((state: RootState) => state.school)

    const navigate = useNavigate()

    const [onSearch$] = useState(() => new Subject());

    useEffect(() => {
        const subscription = onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
        ).subscribe((value) => setFilters({search: value as string}));

        return () => subscription.unsubscribe()
    }, [])

    useEffect(() => {
        setLoading(true)

        fetchCompetitors(school.id!, filters.search).subscribe(value => {
            setCompetitors(value)
            setLoading(false)
        })
    }, [filters.search])

    const navigateToEdit = (id: number) => {
        navigate(`./${id}`)
    }

    const onSearchChange = (event: any) => onSearch$.next(event.target.value)

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                size="middle"
                name="filtersForm"
                style={{marginTop: "30px"}}
            >
                <Row gutter={100} justify="space-between">
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Ricerca"
                            key={"search"}
                            name="search"
                        >
                            <Input placeholder="Cerca per nome, cognome o codice fiscale" type={"text"} onChange={(value) => onSearchChange(value)}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                id="subscriptionsTable"
                scroll={{x: "max-content"}}
                dataSource={competitors}
                loading={loading}
                rowKey={(record) => record.id + ' ' + Math.random()}
                pagination={{position: ['bottomCenter']}}>
                <Column title="Codice Fiscale" dataIndex="fiscalCode" key="fiscalCode"/>
                <Column title="Cognome" dataIndex="surname" key="surname"/>
                <Column title="Nome" dataIndex="name" key="name"/>
                <Column
                    key="action"
                    align={"end"}
                    render={(value) => (
                        <Space size="middle">
                            <a onClick={() => navigateToEdit(value.id)}>Modifica</a>
                        </Space>
                    )}
                />
            </Table>
        </>
    )
}

export default CompetitorsPage