import {Button, Form, Input} from "antd"
import React, {useEffect} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useDispatch} from "react-redux"
import {notificationActions} from "../../../store/notification.state";
import Title from "antd/es/typography/Title";
import {ICompetitor} from "../../../model/competitor.model";
import {getCompetitor, updateCompetitor} from "../../../service/competitor.service";
import {pageLoadingSActions} from "../../../store/page-loading.state";

const EditCompetitorPage = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if (params.id) {
            dispatch(pageLoadingSActions.showLoading())
            getCompetitor(params.id).subscribe(value => {
                dispatch(pageLoadingSActions.hideLoading())
                if (value) {
                    form.setFieldsValue(value)
                } else {
                    dispatch(notificationActions.error({message: "Competitore non trovato."}))
                    back()
                }
            })
        }
    }, [params.id]);

    const save = (data: ICompetitor) => {
        updateCompetitor(params.id!, data.name!, data.surname!).subscribe(_ => navigate('/competitors'))
    }

    const back = () => {
        navigate(`/competitors`)
    }

    return (
        <>
            <Title level={4}>Modifica i dati del competitore.</Title>
            <Form
                layout="vertical"
                size="middle"
                name="updateCompetitorForm"
                onFinish={save}
                form={form}
            >
                <Form.Item
                    label="Codice fiscale"
                    name={"fiscalCode"}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Nome"
                    name={"name"}
                    rules={[{required: true, message: 'Nome obbligatorio'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Cognome"
                    name={"surname"}
                    rules={[{required: true, message: 'Cognome obbligatorio'}]}
                >
                    <Input/>
                </Form.Item>
                <div className="detail buttons-container">
                    <Button type="default" onClick={back}>
                        Indietro
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Salva
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default EditCompetitorPage