import React, {useEffect, useState} from "react"
import {Card, Col, Image, Row} from "antd"
import {useNavigate} from "react-router-dom"
import {IEvent} from "../../model/event.model";
import {formatterDate} from "../../util/date.formatter";
import {fetchEvents} from "../../service/event.service";
import {useDispatch} from "react-redux";
import {pageLoadingSActions} from "../../store/page-loading.state";


const HomePage = () => {
    const [events, setEvents] = useState<ReadonlyArray<IEvent>>([])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const navigateTo = (eventId: number) => {
        navigate(`/event/${eventId}`)
    }

    useEffect(() => {
        dispatch(pageLoadingSActions.showLoading())

        setTimeout(() => {
            fetchEvents(1).subscribe(value => {
                setEvents(value)
                dispatch(pageLoadingSActions.hideLoading())
            })
        }, 500);
    }, [])

    return (
        <div className="home">
            <div className="events-container">
                <h1 style={{textAlign: "center", fontSize: "30px", marginBottom: "30px"}}>PROSSIMI EVENTI</h1>
                <Row gutter={[30, 30]} justify="space-around">
                    {
                        events && events.length > 0 && (
                            events.map(event => (
                                <Col xs={24} lg={8} xxl={6} key={event.id}>
                                    <Card
                                        onClick={() => navigateTo(event.id)}
                                        hoverable
                                        style={{height: "100%"}}
                                        cover={
                                            <Image
                                                preview={false}
                                                src={event.smallUrl}
                                            />
                                        }>
                                        <h2>{formatterDate(event.date)}</h2>
                                        <h3>{event.description}</h3>
                                    </Card>
                                </Col>
                            ))
                        )
                    }
                </Row>
            </div>
        </div>
    )
}


export default HomePage
