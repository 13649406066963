import "./login.page.css"
import {useDispatch} from "react-redux";
import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {useNavigate} from "react-router-dom";
import {userLogin} from "../../../service/user.service";

interface LoginData {
    readonly email: string
    readonly password: string
}

const LoginPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const login = ({email, password}: LoginData) => {
        userLogin(email, password).subscribe(() => {
            // TODO rifare logica per capire se è admin o meno
            // dispatch(loginActions.login('ROLE_ADMIN'))
            navigate('/')
        })
    }

    return (
        <div className="form-container">
            <Form
                layout="vertical"
                size="middle"
                name="loginForm"
                initialValues={{remember: false}}
                onFinish={login}
                autoComplete="off"
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{required: true, message: 'Please input your email!'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{required: true, message: 'Please input your password!'}]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}


export default LoginPage