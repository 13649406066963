import {Outlet} from "react-router-dom";
import {Layout, Spin} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";
import MenuComponent from "../../components/menu/menu.component";
import FooterComponent from "../../components/footer/footer.component";
import React from "react";
import './root-layout.page.css'
import RegistrationErrorModal from "../../components/modals/registration-error.modal";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {registrationErrorModalActions} from "../../store/registration-error-modal.state";

function RootLayoutPage() {

    const dispatch = useDispatch()

    const registrationErrorModal = useSelector((state: RootState) => {
        return state.registrationErrorModal
    })

    const closeModal = () => {
        dispatch(registrationErrorModalActions.hide())
    }

    const pageLoading = useSelector((state: RootState) => {
        return state.pageLoading
    })

    return (
        <>
            <Layout className="custom-layout">
                <Header className="custom-header"><MenuComponent/></Header>
                <Content className="custom-content">
                    <Spin spinning={pageLoading.visible} size="large" style={{ height: '1000px' }}>
                        <Outlet/>
                    </Spin>
                    <RegistrationErrorModal isOpen={registrationErrorModal.isVisible}
                                            onCloseModal={closeModal}></RegistrationErrorModal>
                </Content>
                <Footer><FooterComponent/></Footer>
            </Layout>
        </>
    )
}

export default RootLayoutPage