import API from "../api";
import {pipe} from "fp-ts/function";
import {from, Observable} from "rxjs";
import * as OBS from "fp-ts-rxjs/Observable";
import {IDiscipline} from "../model/discipline.model";
import {stringify} from "qs";

const BASE_PATH = 'discipline'

export const fetchDisciplines = (eventId: string, disciplineType: ReadonlyArray<number>): Observable<ReadonlyArray<IDiscipline>> => {
    return pipe(
        from(
            API.get(BASE_PATH, {
                params: {
                    eventId,
                    disciplineType
                },
                paramsSerializer: params => {
                    return stringify(params)
                }
            })
        ),
        OBS.map((res): ReadonlyArray<IDiscipline> => res.data)
    )
}
