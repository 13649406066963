import {createSlice} from "@reduxjs/toolkit";

export interface PageLoadingState {
    visible: boolean
}

const initialState: PageLoadingState = {
    visible: false
}

const pageLoadingSlice = createSlice({
    name: 'pageLoading',
    initialState,
    reducers: {
        showLoading(state) {
            state.visible = true
        },
        hideLoading(state) {
            state.visible = false

        },
    }
})

export const pageLoadingSActions = pageLoadingSlice.actions
export const pageLoadingSReducer = pageLoadingSlice.reducer
