import API from "../api";
import {pipe} from "fp-ts/function";
import {from, Observable} from "rxjs";
import * as OBS from "fp-ts-rxjs/Observable";
import {ISubscriptionData} from "../model/subscription.model";
import {ITotalSubscriptions} from "../model/export.model";

const BASE_PATH = 'export'

export const exportBySchoolAndEvent = (eventId: number | string, schoolId: number): Observable<ReadonlyArray<ISubscriptionData>> => {
    return pipe(
        from(API.get(BASE_PATH + "/bySchoolAndEvent", {
            params: {
                eventId,
                schoolId
            }
        })),
        OBS.map((res): ReadonlyArray<ISubscriptionData> => res.data)
    )
}

export const exportByEvent = (eventId: number | string): Observable<ITotalSubscriptions> => {
    return pipe(
        from(API.get(BASE_PATH + "/byEvent", {
            params: {
                eventId
            }
        })),
        OBS.map((res): ITotalSubscriptions => res.data)
    )
}