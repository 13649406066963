import API from "../api";
import {pipe} from "fp-ts/function";
import {from, Observable} from "rxjs";
import * as OBS from "fp-ts-rxjs/Observable";
import {IClass} from "../model/class.model";

const BASE_PATH = 'class'

export const fetchClasses = (eventId: string, disciplineId: number): Observable<ReadonlyArray<IClass>> => {
    return pipe(
        from(
            API.get(BASE_PATH, {
                params: {
                    eventId,
                    disciplineId
                }
            })
        ),
        OBS.map((res): ReadonlyArray<IClass> => res.data)
    )
}
