import API from "../api";
import {pipe} from "fp-ts/function";
import {from, Observable} from "rxjs";
import * as OBS from "fp-ts-rxjs/Observable";
import {LoginResponse} from "../components/login-guard/login-guard.page";

const BASE_PATH = 'user'

export const userLogin = (email: string, password: string): Observable<LoginResponse> => {
    return pipe(
        from(API.post(BASE_PATH + "/login", {
            email,
            password
        })),
        OBS.map((res): LoginResponse => res.data)
    )
}

export const userRegister = (email: string, password: string): Observable<any> => {
    return pipe(
        from(API.post(BASE_PATH + "/register", {
            email,
            password
        })),
        OBS.map((res): ReadonlyArray<any> => res.data)
    )
}

export const userConfirm = (): Observable<any> => {
    return pipe(
        from(API.post(BASE_PATH + "/confirm", {code: 'todo'})), //TODO per confirmCode
        OBS.map((res): ReadonlyArray<any> => res.data)
    )
}

export const userInitialize = (): Observable<any> => {
    return pipe(
        from(API.post(BASE_PATH + "/initialize")),
        OBS.map((res): ReadonlyArray<any> => res.data)
    )
}

export const userVerifyResetKey = (email: string, key: string): Observable<any> => {
    return pipe(
        from(API.post(BASE_PATH + "/verify-reset-key", {email, key})),
        OBS.map((res): ReadonlyArray<any> => res.data)
    )
}

export const userResetPasswordRequest = (email: string): Observable<any> => {
    return pipe(
        from(API.post(BASE_PATH + "/reset-password-request", {email})),
        OBS.map((res): ReadonlyArray<any> => res.data)
    )
}

export const userResetPassword = (email: string, newPassword: string): Observable<any> => {
    return pipe(
        from(API.post(BASE_PATH + "/reset-password", {email, newPassword})),
        OBS.map((res): ReadonlyArray<any> => res.data)
    )
}

export const userChangePassword = (userId: number, currentPassword: string, newPassword: string): Observable<any> => {
    return pipe(
        from(API.post(BASE_PATH + "/change-password", {
            userId,
            currentPassword,
            newPassword
        })),
        OBS.map((res): ReadonlyArray<any> => res.data)
    )
}

export const updateUserEmail = (id: number, email: string): Observable<any> => {
    return pipe(
        from(API.post(BASE_PATH + "/email", {id, email})),
        OBS.map((res): ReadonlyArray<any> => res.data)
    )
}
