import {Button, Col, Form, Input, Row} from "antd"
import React, {useState} from "react"
import './reset-password-request.page.css'
import Title from "antd/es/typography/Title"
import {userResetPasswordRequest} from "../../../service/user.service";
import {lastValueFrom} from "rxjs";


const ResetPasswordRequestPage = () => {
    const [requestSent, setRequestSent] = useState<boolean>(false)
    const [isSending, setIsSending] = useState<boolean>(false)

    const [form] = Form.useForm()

    const restorePassword = (data: any) => {
        setIsSending(true)

        lastValueFrom(userResetPasswordRequest(data.email)).then(() => {
            setRequestSent(true)
            setIsSending(false)
        }).catch(() => setIsSending(false))
    }

    const trimEmailOnChange = (inputText: string) => {
        form.setFieldValue('email', inputText.trim())
        form.validateFields(['email'])
    }

    return (
        <>
            {!requestSent ? (
                <>
                    <Title level={4} style={{marginBottom: '20px', textAlign: 'center'}}>Sarà inviata un'email per
                        resettare la
                        password
                        all'indirizzo indicato.</Title>
                    <Row style={{display: "flex", justifyContent: 'center'}}>
                        <Col xs={24} md={10}>
                            <Form
                                layout="vertical"
                                size="middle"
                                initialValues={{remember: false}}
                                onFinish={restorePassword}
                                autoComplete="off"
                                form={form}
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{required: true, message: 'Email obbligatoria'}, {
                                        type: 'email',
                                        message: "L'email non è valida"
                                    }]}
                                >
                                    <Input onChange={(e) => trimEmailOnChange(e.target.value)}/>
                                </Form.Item>

                                <div style={{marginTop: "10px", display: "flex", justifyContent: "center"}}>
                                    <Button type="primary" htmlType="submit" disabled={isSending}>
                                        Richiedi
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Title level={4} style={{marginBottom: '20px', textAlign: 'center'}}>E' stata inviata un'email con i
                        dettagli su come reimpostare la password.</Title>
                    <Title level={5} style={{marginBottom: '20px', textAlign: 'center'}}>Nel caso in cui l'email non
                        sia
                        presente controlla lo
                        spam.</Title>

                    <Title level={5} style={{marginBottom: '20px', textAlign: 'center'}}>Puoi chiuedere questa
                        finestra.</Title>
                </>
            )}
        </>
    )
}

export default ResetPasswordRequestPage