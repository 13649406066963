import {Navigate, Outlet, useLocation, useParams} from 'react-router-dom'
import * as React from "react"
import {useEffect, useState} from "react"
import {getEvent} from "../service/event.service";

const ActiveEventRoute = () => {
    const location = useLocation()
    const params = useParams()
    const [isEventActive, setEventActive] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (!params.eventId) {
            setLoading(false)
            return
        }

        getEvent(params.eventId).subscribe(value => {
            setEventActive(value.active)
            setLoading(false)
        })
    }, [params.eventId])

    if (isLoading) {
        return <></>
    }

    return isEventActive ? <Outlet/> : <Navigate to={{pathname: "/"}} replace state={{from: location}}/>
}

export default ActiveEventRoute