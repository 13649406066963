import API from "../api"
import {pipe} from "fp-ts/function"
import {from, Observable} from "rxjs"
import * as OBS from "fp-ts-rxjs/Observable"
import {ICompetitor} from "../model/competitor.model"
import {ISubscriptionData, SubscriptionCreateRequest} from "../model/subscription.model"
import {stringify} from "qs"

const BASE_PATH = 'subscription'

export const fetchSubscriptions = (schoolId: number, eventId: number, disciplineType: ReadonlyArray<number>): Observable<ReadonlyArray<ISubscriptionData>> => {
    return pipe(
        from(
            API.get(BASE_PATH + "/all", {
                params: {
                    eventId,
                    schoolId,
                    disciplineType
                },
                paramsSerializer: params => {
                    return stringify(params)
                }
            })
        ),
        OBS.map((res): ReadonlyArray<ISubscriptionData> => res.data)
    )
}

export const deleteSubscription = (id: number): Observable<any> => {
    return pipe(
        from(API.delete(BASE_PATH, {
            params: {
                id
            }
        })),
        OBS.map(res => res.data)
    )
}

export const createSubscription = (schoolId: number, request: SubscriptionCreateRequest): Observable<any> => {
    return pipe(
        from(
            API.post(BASE_PATH, request, {
                params: {
                    schoolId
                }
            })
        ),
        OBS.map((res): ICompetitor => res.data)
    )
}