import axios, {AxiosError, AxiosResponse} from 'axios'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {notificationActions} from '../store/notification.state'
import configData from '../config/config.json'

export interface CustomAxiosError {
    readonly message: string
    readonly code?: number
}

const axiosInstance = axios.create({
    baseURL: `${configData.SERVER_URL}`,
    headers: {
        'Content-Type': 'application/json'
    }
})

const reqInterceptor = (config: any) => {
    const token = localStorage.getItem('token')

    if (token) {
        if (!config.headers) {
            config.headers = {}
        }
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}

const resInterceptor = (response: AxiosResponse) => {
    return response
}

const errInterceptor = (error: AxiosError) => {
    return Promise.reject(error)
}

const requestInterceptor = axiosInstance.interceptors.request.use(reqInterceptor, errInterceptor)
const responseInterceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor)

const ejectInterceptors = () => {
    axiosInstance.interceptors.request.eject(requestInterceptor)
    axiosInstance.interceptors.response.eject(responseInterceptor)
}

// @ts-ignore
const AxiosInterceptor = ({ children }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        const errorInterceptor = (error: AxiosError) => {
            dispatch(
                notificationActions.error({
                    code: error.response ? error.response.status : 500,
                    message: error.response ? (error.response.data as string) : 'Errore',
                })
            )
            return Promise.reject(error)
        }

        // Reapply error handling for responses (since request interceptors are already set globally)
        axiosInstance.interceptors.response.use(resInterceptor, errorInterceptor)

        return () => {
            ejectInterceptors() // Eject the interceptors on unmount to avoid memory leaks
        }
    }, [dispatch])

    return children
}

export default axiosInstance
export { AxiosInterceptor }